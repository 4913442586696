<template>
  <section>
    <b-row class="flex-grow-1 px-1">
      <b-col lg="6">
        <validation-provider
          #default="{ errors }"
          name="Taxi"
          rules="required"
        >
          <b-form-group
            label="Taxi"
            label-for="taxi"
          >
            <v-select
              :clearable="false"
              :options="taxiList"
              @input="(taxi) => taxiSelected(taxi)"
              label="licenceNum"
              v-model="tour.taxi"
            >
              <template #option="{ firstName, lastName, licenceNum }">
                <span>
                  <span class="font-weight-bolder">{{ licenceNum }}</span>
                  -
                  <small>{{ `${firstName} ${lastName}` }}</small>
                </span>
              </template>

              <template #selected-option="{ firstName, lastName, licenceNum }">
                <span>
                  <span class="font-weight-bolder">{{ licenceNum }}</span>
                  -
                  <small>{{ `${firstName} ${lastName}` }}</small>
                </span>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col lg="6">
          <b-form-group
            label="Vehicle"
            label-for="vehicle"
          >
            <v-select
              :clearable="false"
              :options="taxiVehiclesList"
              @input="(vehicle) => vehicleSelected(vehicle)"
              label="model"
              v-model="tour.vehicle"
            >
              <template #no-options>Please, select the taxi</template>
              <template #option="{ model, regNum }">
                <span>
                  <span class="font-weight-bolder">{{ regNum }}</span>
                  -
                  <small>{{ model }}</small>
                </span>
              </template>

              <template #selected-option="{ model, regNum }">
                <span>
                  <span class="font-weight-bolder">{{ regNum }}</span>
                  -
                  <small>{{ model }}</small>
                </span>
              </template>
            </v-select>
          </b-form-group>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import useTourUtils from '@/modules/frontier-tour/composables/useTourUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { storeTour } = useTourUtils();

    const taxiList = store.getters['taxi/taxiList'];
    const tour = computed(() => store.getters['tour/tour']);
    const taxiVehiclesList = ref([]);

    const storeTourData = () => {
      storeTour(tour.value);
    };

    const taxiSelected = (taxi) => {
      const mainVehicle = taxi.vehicles.find((vehicle) => vehicle.isMainVehicle);
      const vehicle = (mainVehicle) ? mainVehicle : taxi.vehicles[0];

      taxiVehiclesList.value = [...taxi.vehicles];

      tour.value.taxi = taxi;
      tour.value.taxiId = taxi.id;
      tour.value.vehicle = vehicle;
      tour.value.vehicleId = vehicle ? vehicle.id : null;
      storeTour(tour.value);
    };

    const vehicleSelected = (vehicleSelected) => {
      tour.value.vehicleId = vehicleSelected.id;
      storeTour(tour.value);
    };

    return {
      // Reactive
      tour,
      taxiList,
      taxiVehiclesList,

      //Method
      taxiSelected,
      storeTourData,
      vehicleSelected
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    flatPickr,
    ValidationProvider,
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
