<template>
  <section
    class="invoice-add-wrapper"
    v-if="dataLoaded"
  >
    <b-row class="invoice-add">
      <b-col lg="8">
        <validation-observer ref="tourForm">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding pb-0">
                <tour-voucher-date />

                <tour-type />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <tour-taxi />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <tour-note />
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col lg="4">
        <tour-summary-card @submitTour="createNewTour" />

        <tour-adjust-fee />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardBody, BCol, BForm, BFormCheckbox, BRow } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationObserver } from 'vee-validate';
import router from '@/router';
import store from '@/store';
import TourType from '@/modules/frontier-tour/components/TourType.vue';
import TourAdjustFee from '@/modules/frontier-tour/components/TourAdjustFee.vue';
import TourNote from '@/modules/frontier-tour/components/TourNote.vue';
import TourSummaryCard from '@/modules/frontier-tour/components/TourSummaryCard.vue';
import TourTaxi from '@/modules/frontier-tour/components/TourTaxi.vue';
import TourVoucherDate from '@/modules/frontier-tour/components/TourVoucherDate.vue';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useTourType from '@/modules/frontier-tour-settings/composables/useTourType';
import useUtils from '@/composables/useUtils';
import useGlobalSettings from '@/modules/setting/composables/useGlobalSettings';
import useTour from '@/modules/frontier-tour/composables/useTour';

export default {
  setup() {
    const { getTaxiList } = useTaxi();
    const { getTourTypeList } = useTourType();
    const { convertDateToUTC } = useUtils();
    const { getGlobalSetting } = useGlobalSettings();
    const { createTour, getTour, updateTour } = useTour();

    const tourData = computed(() => store.getters['tour/tour']);
    const tourForm = ref(null);
    const globalSettings = ref(null);
    const dataLoaded = ref(false);

    const createNewTour = () => {
      const tour = store.getters['tour/tour'];
      tourForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const result = (tour.id === null)
          ? await createTour({
            ...tour,
            tourDateUtc: convertDateToUTC(tour.tourDateUtc),
          })
          : await updateTour({
            ...tour,
            tourDateUtc: convertDateToUTC(tour.tourDateUtc),
          });

        if (result === null) return;

        router.push({
          name: 'frontier-tour-detail',
          params: { id: result.id },
        });
      });
    };

    const initData = async () => {
      store.commit('tour/resetTour');
      globalSettings.value = await getGlobalSetting();
      await getTourTypeList();
      await getTaxiList();
      if (router.currentRoute.params.id) await getTour(router.currentRoute.params.id)
    };

    initData().then(_ => {
      dataLoaded.value = true;
    });

    return {
      // Reactive
      tourData,
      tourForm,
      dataLoaded,

      // Method
      createNewTour,

      // Validator
      required,
    };
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BForm,
    BFormCheckbox,
    BRow,
    TourType,
    TourAdjustFee,
    TourNote,
    TourSummaryCard,
    TourTaxi,
    TourVoucherDate,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
