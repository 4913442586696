var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex flex-sm-row flex-column justify-content-between align-items-sm-center"},[_c('b-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('validation-provider',{staticClass:"select",attrs:{"name":"Tour Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tour Type","label-for":"tour-type"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.tourTypeList,"input-id":"tour-type","label":"name"},on:{"input":function (tourType) { return _vm.tourTypeSelected(tourType); }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
var rate = ref.rate;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(name)+" ")]),_c('span',{staticClass:"text-truncate text-muted overflow-hidden"},[_vm._v(" (£"+_vm._s(rate)+") ")])]}},{key:"option",fn:function(ref){
var name = ref.name;
var rate = ref.rate;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(name)+" ")]),_c('span',{staticClass:"text-truncate text-muted overflow-hidden"},[_vm._v(" (£"+_vm._s(rate)+") ")])]}}],null,true),model:{value:(_vm.tour.tourType),callback:function ($$v) {_vm.$set(_vm.tour, "tourType", $$v)},expression:"tour.tourType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }