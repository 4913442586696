import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useTourType = () => {
  const toast = useToast();

  const getTourType = async (tourTypeId) => {
    return await store.dispatch('tourType/get', tourTypeId);
  };

  const getTourTypeList = async (params) => {
    try {
      return await store.dispatch('tourType/getList', params);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of tour types',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    //Method
    getTourType,
    getTourTypeList,
  };
};

export default useTourType;
