var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"flex-grow-1 px-1"},[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Taxi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Taxi","label-for":"taxi"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.taxiList,"label":"licenceNum"},on:{"input":function (taxi) { return _vm.taxiSelected(taxi); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}}],null,true),model:{value:(_vm.tour.taxi),callback:function ($$v) {_vm.$set(_vm.tour, "taxi", $$v)},expression:"tour.taxi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Vehicle","label-for":"vehicle"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.taxiVehiclesList,"label":"model"},on:{"input":function (vehicle) { return _vm.vehicleSelected(vehicle); }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Please, select the taxi")]},proxy:true},{key:"option",fn:function(ref){
var model = ref.model;
var regNum = ref.regNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(regNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s(model))])])]}},{key:"selected-option",fn:function(ref){
var model = ref.model;
var regNum = ref.regNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(regNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s(model))])])]}}]),model:{value:(_vm.tour.vehicle),callback:function ($$v) {_vm.$set(_vm.tour, "vehicle", $$v)},expression:"tour.vehicle"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }