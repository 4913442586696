<template>
    <b-card>
        <b-card-header>
            <div class="d-flex justify-content-between align-items-center w-100">
                <b-card-title>Summary</b-card-title>
                <b-badge
                    v-if="tour.tourType !== null"
                    :variant="`light-primary`"
                >
                    {{ tour.tourType.name }}
                </b-badge>
            </div>
        </b-card-header>

        <b-card-body
            class="pb-0"
            v-if="tour.taxi !== null"
        >
            <b-col class="px-0">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0">
                            {{ tour.taxi.firstName }} {{ tour.taxi.lastName }}
                        </h6>
                        <span class="text-muted">{{ tour.taxi.licenceNum }}</span>
                    </div>
                    <p class="mb-25">{{ formatCurrency(tour.taxiCommission) }}</p>
                </div>
            </b-col>
        </b-card-body>

        <hr class="invoice-spacing" />

        <b-card-body class="py-0">
            <b-col class="px-0">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0">
                            Frontier Acc.
                        </h6>
                    </div>
                    <p class="mb-25">{{ formatCurrency(tour.accountCommission) }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0">
                            JURA Account
                        </h6>
                    </div>
                    <p class="mb-25">{{ formatCurrency(tour.juraAccCommission) }}</p>
                </div>
            </b-col>
        </b-card-body>

        <hr class="invoice-spacing" />

        <b-card-body class="py-0">
            <b-col>
                <b-row class="justify-content-end align-items-center my-2">
                    <h4 class="pr-25">TOTAL</h4>
                    <h4 class="pl-25">
                        {{ formatCurrency(tour.tourTotalFee) }}
                    </h4>
                </b-row>
            </b-col>
        </b-card-body>

        <b-button
            block
            variant="primary"
            @click="$emit('submitTour')"
        >
            {{ tour.id ? 'Edit' : 'Add' }} Tour
        </b-button>
    </b-card>
</template>

<script>
import {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormCheckbox,
    BRow,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import store from '@/store';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
    setup() {
        const { formatCurrency } = useUtils();

        const tour = computed(() => store.getters['tour/tour']);

        return {
            // Reactive
            tour,

            //Method
            formatCurrency,
        };
    },
    components: {
        BBadge,
        BButton,
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BCol,
        BFormCheckbox,
        BRow,
        ValidationObserver,
        ValidationProvider,
        vSelect,
    },
};
</script>
