<template>
  <div>
    <b-form-group
      label="Adjust Tour Fee"
      label-for="adjust-tour-fee"
      description="Press ENTER to apply. Input 0 to reset."
    >
      <b-form-input
        @keyup.enter="adjustTourFee($event.target.value)"
        id="adjust-tour-fee"
        type="number"
        v-model="tour.tourAdjustmentFee"
      />
    </b-form-group>
    <b-form-group
      label="Adjust Frontier Fee"
      label-for="adjust-tour-fee"
      description="Press ENTER to apply. Input 0 to reset."
    >
      <b-form-input
        @keyup.enter="adjustFrontierFee($event.target.value)"
        id="adjust-frontier-fee"
        type="number"
        v-model="tour.accountAdjustmentFee"
      />
    </b-form-group>
    <b-form-group
      label="Adjust JURA Fee"
      label-for="adjust-jura-fee"
      description="Press ENTER to apply. Input 0 to reset."
    >
      <b-form-input
        @keyup.enter="adjustJuraFee($event.target.value)"
        id="adjust-jura-fee"
        type="number"
        v-model="tour.juraAdjustmentFee"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import store from '@/store';
import useTourUtils from '@/modules/frontier-tour/composables/useTourUtils';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { getTourFees, storeTour } = useTourUtils();
    const { formatDecimalNumber } = useUtils();

    const tour = computed(() => store.getters['tour/tour']);

    const adjustTourFee = (value) => {
      const amount = parseFloat(value);
      if (!value || Number.isNaN(value)) return;

      tour.value.tourAdjustmentFee = amount;

      const {
        accountCommission,
        juraAccCommission,
        taxiCommission,
        tourTotalFee } = getTourFees(tour.value);

      tour.value.accountCommission = accountCommission;
      tour.value.juraAccCommission = juraAccCommission;
      tour.value.taxiCommission = taxiCommission;
      tour.value.tourTotalFee = tourTotalFee;

      storeTour(tour.value);
    };

    const adjustFrontierFee = (value) => {
      const amount = parseFloat(value);
      if (!value || Number.isNaN(value)) return;

      tour.value.accountAdjustmentFee = amount;

      const {
        accountCommission,
        juraAccCommission,
        taxiCommission,
        tourTotalFee } = getTourFees(tour.value);

      tour.value.accountCommission = accountCommission;
      tour.value.juraAccCommission = juraAccCommission;
      tour.value.taxiCommission = taxiCommission;
      tour.value.tourTotalFee = tourTotalFee;

      storeTour(tour.value);
    };

    const adjustJuraFee = (value) => {
      const amount = parseFloat(value);
      if (!value || Number.isNaN(value)) return;

      tour.value.juraAdjustmentFee = amount;

      const {
        accountCommission,
        juraAccCommission,
        taxiCommission,
        tourTotalFee } = getTourFees(tour.value);

      tour.value.accountCommission = accountCommission;
      tour.value.juraAccCommission = juraAccCommission;
      tour.value.taxiCommission = taxiCommission;
      tour.value.tourTotalFee = tourTotalFee;

      storeTour(tour.value);
    };

    return {
      // Reactive
      tour,

      // Method
      adjustTourFee,
      adjustJuraFee,
      adjustFrontierFee
    };
  },
  components: {
    BFormGroup,
    BFormInput,
  },
};
</script>
