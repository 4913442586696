<template>
  <div class="px-1">
    <span class="font-weight-bold">Note: </span>
    <b-form-textarea
      @input="addNotes"
      v-model="tour.notes"
    />
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import useTourUtils from '@/modules/frontier-tour/composables/useTourUtils';
import store from '@/store';

export default {
  setup() {
    const { storeTour } = useTourUtils();

    const tour = computed(() => store.getters['tour/tour']);

    const addNotes = () => {
      storeTour(tour.value);
    };

    return {
      // Reactive
      tour,

      // Method
      addNotes
    };
  },
  components: { BFormTextarea },
};
</script>
