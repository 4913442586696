<template>
  <section>
    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center">
      <b-col sm="6">
        <div class="d-flex align-items-center">
          <validation-provider
            #default="{ errors }"
            name="Tour Type"
            rules="required"
            class="select"
          >
            <b-form-group
              label="Tour Type"
              label-for="tour-type"
            >
              <v-select
                :clearable="false"
                :options="tourTypeList"
                @input="(tourType) => tourTypeSelected(tourType)"
                input-id="tour-type"
                label="name"
                v-model="tour.tourType"
              >
                <template #selected-option="{ name, rate }">
                  <span class="text-truncate overflow-hidden">
                    {{ name }}
                  </span>

                  <span class="text-truncate text-muted overflow-hidden">
                    (£{{ rate }})
                  </span>
                </template>

                <template #option="{ name, rate }">
                  <span class="text-truncate overflow-hidden">
                    {{ name }}
                  </span>

                  <span class="text-truncate text-muted overflow-hidden">
                    (£{{ rate }})
                  </span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import useTourUtils from '@/modules/frontier-tour/composables/useTourUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { getTourFees, storeTour } = useTourUtils();

    const tourTypeList = store.getters['tourType/tourTypes'];
    const tour = computed(() => store.getters['tour/tour']);

    const tourTypeSelected = (tourType) => {
      tour.value.tourType = tourType;
      tour.value.tourTypeId = tourType.id;

      const {
        accountCommission,
        juraAccCommission,
        taxiCommission,
        tourTotalFee } = getTourFees(tour.value);

      tour.value.accountCommission = accountCommission;
      tour.value.juraAccCommission = juraAccCommission;
      tour.value.taxiCommission = taxiCommission;
      tour.value.tourTotalFee = tourTotalFee;

      storeTour(tour.value);
    };

    return {
      // Reactive
      tour,
      tourTypeList,

      //Method
      tourTypeSelected,
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.select {
  width: 100%;
  max-width: 197px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.tour-header {
  .title {
    width: 115px;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
